<template>
  <Box>
    <div class="page-service-airway">
      <Search
        @on-edit="onEdit"
        @on-search="handle_search"
      />
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #time="{ row }">
          <div class="action">
            {{ getTime(row.time) === "00s" ? '暂无' : getTime(row.time) }}
          </div>
        </template>
        <template #issafe="{ row }">
          <div class="action">
            <span v-if="row.issafe === '安全'" style="color: #16D979">安全</span>
            <span v-else>/</span>
            <!-- {{ row.issafe === '安全' ? '安全': '/' }} -->
          </div>
        </template>
        
        <template #action="{ row }">
          <div class="action">
            <span v-view="{sign:'hn:task-web:viewFlight'}" v-if="routeButton1" title="预览航线" class="iconfont icon-feihangguiji" @click="log(row)"></span>
            <span :title="row.issafe === '安全' ? '取消安全' : '安全' " class="iconfont icon-anquan-xuanzhong"  @click="editIssafe(row)"></span>
            <span v-view="{sign:'airline:edit'}" v-if="routeButton2" title="更改" class="iconfont icon-genggai" @click="edit(row)"></span>
            <span title="删除" class="iconfont icon-shanchu" @click="del(row)"></span>
          </div>
        </template>
      </Table>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import Dialog from "@/components/common/dialog";
import { props } from "./props";
import API from "@/api";
import { getTime }  from '@/utils/unti.js'
export default {
  props: _.pick(props, ["onEdit", "edit", "lineList"]),
  components: { Box, Search, Table , Dialog},
  methods: {},
  data() {
    return {
      columns: [
        { prop: "flightName", label: "航线名称" },
        { prop: "pointCount", label: "航点数量（个）" },
        { prop: "distance", label: "航线长度（m）" },
        { prop: "time", slot: true, label: "预计时长" },
        { prop: "organizationName", label: "所属单位" },
        { prop: "status", label: "航线状态" },
        { prop: "dutyOrganName", slot: true, label: "安全状态" },
        { prop: "action", slot: true, label: "操作" },
      ],
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      map: {
        status: {
          1: "启用",
          0: "禁用"
        },
        issafe: {
          0: "暂定",
          1: "安全",
        }
      },
      routeButton1: false,
      routeButton2: false,
      showDel:false
    };
  },
  methods: {
    getTime,
    async async_get_list() {
      const { map } = this;
      const { page, pageSize } = this.pagination;
      let res = await API.AIRWAY.flightLineList({
        ...this.search,
        pageNo: page,
        pageSize: pageSize,
      });
      res = res.data
      this.dataSource = res.data || [];
      this.dataSource = this.dataSource.map((item) => ({
        ...item,
        status: map.status[item.status],
        issafe: map.issafe[item.issafe],
      }));
      // this.pagination.page = res.pageNo;
      this.pagination.pageSize = res.pageSize;
      this.pagination.total = res.totalCount;
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },
    log(data) {
    this.lineList(data)
    },
     del(data) {
      this.$confirm('删除航线后无法恢复，是否继续删除？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
            const res = await API.AIRWAY.deleteFlightLine({id: data.id})
            if (res.status === 1) {
              this.$message.success('删除成功')
              if(this.dataSource.length === 1) {
                  if(this.pagination.page > 1) {
                    this.pagination.page --
                  }
                }
              this.async_get_list();
            } else {
              this.$message.error(res.message)
            }
        }).catch(() => {
                 
        });
     
    },
  async editIssafe(data) {
    let res = await API.AIRWAY.editIssafe({
      id: data.id,
      issafe: data.issafe === '安全' ? null : 1
    })
    if (res.code === 200) {
      this.$message.success('安全状态修改成功')
      this.async_get_list();
    } else {
      this.$message.success(res.message)
    }
  }
  },
  created () {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:task-web:viewFlight") > -1) {
      this.routeButton1 = true
    } 
    if (permissionList.indexOf("airline:edit") > -1) {
      this.routeButton2 = true
    }
  },
  mounted() {
    this.async_get_list();
  },
};
</script>

<style lang="scss" scoped>
.page-service-airway {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  .form-submit-item{
    display: flex;
    justify-content: space-evenly;
    margin-top: 41px;
  }
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 26px;
        color: #9fc4ff;
        margin-right: 4px;
        line-height: 26px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>