var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"page-service-airway"},[_c('Search',{on:{"on-edit":_vm.onEdit,"on-search":_vm.handle_search}}),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
          _vm.pagination.pageSize = size;
          _vm.async_get_list();
        },"page-change":(page) => {
          _vm.pagination.page = page;
          _vm.async_get_list();
        }},scopedSlots:_vm._u([{key:"time",fn:function({ row }){return [_c('div',{staticClass:"action"},[_vm._v(" "+_vm._s(_vm.getTime(row.time) === "00s" ? '暂无' : _vm.getTime(row.time))+" ")])]}},{key:"issafe",fn:function({ row }){return [_c('div',{staticClass:"action"},[(row.issafe === '安全')?_c('span',{staticStyle:{"color":"#16D979"}},[_vm._v("安全")]):_c('span',[_vm._v("/")])])]}},{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"},[(_vm.routeButton1)?_c('span',{directives:[{name:"view",rawName:"v-view",value:({sign:'hn:task-web:viewFlight'}),expression:"{sign:'hn:task-web:viewFlight'}"}],staticClass:"iconfont icon-feihangguiji",attrs:{"title":"预览航线"},on:{"click":function($event){return _vm.log(row)}}}):_vm._e(),_c('span',{staticClass:"iconfont icon-anquan-xuanzhong",attrs:{"title":row.issafe === '安全' ? '取消安全' : '安全'},on:{"click":function($event){return _vm.editIssafe(row)}}}),(_vm.routeButton2)?_c('span',{directives:[{name:"view",rawName:"v-view",value:({sign:'airline:edit'}),expression:"{sign:'airline:edit'}"}],staticClass:"iconfont icon-genggai",attrs:{"title":"更改"},on:{"click":function($event){return _vm.edit(row)}}}):_vm._e(),_c('span',{staticClass:"iconfont icon-shanchu",attrs:{"title":"删除"},on:{"click":function($event){return _vm.del(row)}}})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }